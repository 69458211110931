<template>
  <g>
    <svg:style>
      .cls-4 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
      }

      .cls-4 {
        stroke-width: 0.25px;
      }

      .cls-4 {
        fill: url(#linear-gradient-3);
      }

      .cls-6 {
        fill: url(#glass-pattern);
      }
      .Solid { fill: {{ doorSolidColor }} }
      .Molding-L { fill: url(#Molding-L); } /* left */
      .Molding-T { fill: url(#Molding-T); } /* top */
      .Molding-R { fill: url(#Molding-R); } /* right */
      .Molding-B { fill: url(#Molding-B); } /* bottom */
    </svg:style>
    <linearGradient
      id="linear-gradient"
      :x1="doorLeftWidth1 + 71.69"
      :y1="doorTopHeight1 + 296.32"
      :x2="doorLeftWidth1 + 71.69"
      :y2="doorTopHeight1 - 0.6"
      gradientTransform="matrix(1, 0, 0, -1, -0.88, 296.44)"
      gradientUnits="userSpaceOnUse">
      <stop offset="0.25" stop-color="#f9f9f9"/>
      <stop offset="0.67" stop-color="#e8e8e8"/>
      <stop offset="0.79" stop-color="#e2e2e2"/>
    </linearGradient>
    <linearGradient id="linear-gradient-2"
      :x1="doorLeftWidth1 + 12.17"
      :y1="doorTopHeight1 + 146.03"
      :x2="doorLeftWidth1 + 17.83"
      :y2="doorTopHeight1 + 146.03"
      gradientTransform="matrix(1, 0, 0, -1, -0.88, 296.44)" gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="linear-gradient-3"
      :x1="doorLeftWidth1 + 71.69"
      :y1="doorTopHeight1 + 0.54"
      :x2="doorLeftWidth1 + 71.69"
      :y2="doorTopHeight1 + 341.41"
      xlink:href="#linear-gradient-2"/>
    <filter id="inset-shadow" name="inset-shadow">
      <feOffset/>
      <feGaussianBlur result="offset-blur" stdDeviation="1"/>
      <feComposite result="inverse" operator="out" in="SourceGraphic" in2="offset-blur"/>
      <feFlood result="color" flood-color="black"/>
      <feComposite result="shadow" operator="in" in="color" in2="inverse"/>
      <feComponentTransfer result="shadow" in="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite in="shadow" in2="SourceGraphic"/>
    </filter>
    <!-- Molding gradient BEGIN -->
    <linearGradient id="Molding-L" data-name="Molding" x1="0" y1="0" x2="100%" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-T" data-name="Molding" x1="0" y1="0" x2="0" y2="100%">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-R" data-name="Molding" x1="100%" y1="0" x2="0" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-B" data-name="Molding" x1="0" y1="100%" x2="0" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <!-- Molding gradient END -->

    <g id="V01">
      <polyline id="inox"
                v-if="showInox"
                data-name="inox"
                class="cls-4"
                :points="`${doorLeftWidth1 + 53.15} ${doorTopHeight1 + 245.91} ${doorLeftWidth1 + 88.48} ${doorTopHeight1 + 245.91} ${doorLeftWidth1 + 88.48} ${doorTopHeight1 + 55.03} ${doorLeftWidth1 + 53.15} ${doorTopHeight1 + 55.03} ${doorLeftWidth1 + 53.15} ${doorTopHeight1 + 245.91} ${doorLeftWidth1 + 88.48} ${doorTopHeight1 + 245.91} ${doorLeftWidth1 + 88.48} ${doorTopHeight1 + 55.03} ${doorLeftWidth1 + 53.15} ${doorTopHeight1 + 55.03} ${doorLeftWidth1 + 53.15} ${doorTopHeight1 + 245.91} ${doorLeftWidth1 + 88.48} ${doorTopHeight1 + 245.91} ${doorLeftWidth1 + 88.48} ${doorTopHeight1 + 55.03} ${doorLeftWidth1 + 53.15} ${doorTopHeight1 + 55.03} ${doorLeftWidth1 + 53.15} ${doorTopHeight1 + 245.91}`"/>
      <rect filter="url(#inset-shadow)" id="glass" class="cls-6" :x="`${doorLeftWidth1 + 58.1}`" :y="`${doorTopHeight1 + 59.99}`" width="25.45" height="180.98"/>
      <!-- Molding BEGIN -->
      <g id="Molding" data-name="Molding" v-if="showMolding">
        <polygon
          class="Solid"
          :points="`
            ${doorLeftWidth1 + 83.11} ${doorTopHeight1 + 58.82}
            ${doorLeftWidth1 + 57.45} ${doorTopHeight1 + 58.82}
            ${doorLeftWidth1 + 52.62} ${doorTopHeight1 + 54.44}
            ${doorLeftWidth1 + 88.1} ${doorTopHeight1 + 54.44}
            ${doorLeftWidth1 + 83.11} ${doorTopHeight1 + 58.82}
          `"/>
        <polygon
          class="Solid"
          :points="`
            ${doorLeftWidth1 + 57.62} ${doorTopHeight1 + 239.22}
            ${doorLeftWidth1 + 83.28} ${doorTopHeight1 + 239.22}
            ${doorLeftWidth1 + 88.1} ${doorTopHeight1 + 243.6}
            ${doorLeftWidth1 + 52.62} ${doorTopHeight1 + 243.6}
            ${doorLeftWidth1 + 57.62} ${doorTopHeight1 + 239.22}
          `"/>
        <polygon
          class="Solid"
          :points="`
            ${doorLeftWidth1 + 57.45} ${doorTopHeight1 + 58.82}
            ${doorLeftWidth1 + 57.62} ${doorTopHeight1 + 239.22}
            ${doorLeftWidth1 + 52.62} ${doorTopHeight1 + 243.6}
            ${doorLeftWidth1 + 52.62} ${doorTopHeight1 + 54.44}
            ${doorLeftWidth1 + 57.45} ${doorTopHeight1 + 58.82}
          `"/>
        <polygon
          class="Solid"
          :points="`
            ${doorLeftWidth1 + 83.28} ${doorTopHeight1 + 239.22}
            ${doorLeftWidth1 + 83.11} ${doorTopHeight1 + 58.82}
            ${doorLeftWidth1 + 88.1} ${doorTopHeight1 + 54.44}
            ${doorLeftWidth1 + 88.1} ${doorTopHeight1 + 243.6}
            ${doorLeftWidth1 + 83.28} ${doorTopHeight1 + 239.22}
          `"/>
        <polygon
          class="Molding-T"
          :points="`
            ${doorLeftWidth1 + 83.11} ${doorTopHeight1 + 58.82}
            ${doorLeftWidth1 + 57.45} ${doorTopHeight1 + 58.82}
            ${doorLeftWidth1 + 52.62} ${doorTopHeight1 + 54.44}
            ${doorLeftWidth1 + 88.1} ${doorTopHeight1 + 54.44}
            ${doorLeftWidth1 + 83.11} ${doorTopHeight1 + 58.82}
          `"/>
        <polygon
          class="Molding-B"
          :points="`
            ${doorLeftWidth1 + 57.62} ${doorTopHeight1 + 239.22}
            ${doorLeftWidth1 + 83.28} ${doorTopHeight1 + 239.22}
            ${doorLeftWidth1 + 88.1} ${doorTopHeight1 + 243.6}
            ${doorLeftWidth1 + 52.62} ${doorTopHeight1 + 243.6}
            ${doorLeftWidth1 + 57.62} ${doorTopHeight1 + 239.22}
          `"/>
        <polygon
          class="Molding-L"
          :points="`
            ${doorLeftWidth1 + 57.45} ${doorTopHeight1 + 58.82}
            ${doorLeftWidth1 + 57.62} ${doorTopHeight1 + 239.22}
            ${doorLeftWidth1 + 52.62} ${doorTopHeight1 + 243.6}
            ${doorLeftWidth1 + 52.62} ${doorTopHeight1 + 54.44}
            ${doorLeftWidth1 + 57.45} ${doorTopHeight1 + 58.82}
          `"/>
        <polygon
          class="Molding-R"
          :points="`
            ${doorLeftWidth1 + 83.28} ${doorTopHeight1 + 239.22}
            ${doorLeftWidth1 + 83.11} ${doorTopHeight1 + 58.82}
            ${doorLeftWidth1 + 88.1} ${doorTopHeight1 + 54.44}
            ${doorLeftWidth1 + 88.1} ${doorTopHeight1 + 243.6}
            ${doorLeftWidth1 + 83.28} ${doorTopHeight1 + 239.22}
          `"/>
      </g>
      <!-- Molding END -->
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showMolding',
    'doorSolidColor',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
